import {useState, useCallback, useMemo, useEffect, ChangeEvent} from "react";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {ReactComponent as PinOrange} from "../assets/icons/pin-orange.svg";
import {ReactComponent as PinGreen} from "../assets/icons/pin-green.svg";
import {ReactComponent as PinRed} from "../assets/icons/pin-red.svg";
import {ReactComponent as PedestrianLine} from "../assets/icons/pedestrian.svg";
import {ReactComponent as RoadSign} from "../assets/icons/road-sign.svg";
import {getRegions} from "../services/fetchers";


export type FilterType = {
    region_name: string,
    status: string,
    level: string[],
    date_before?: string | null,
    date_after?: string | null,
    type?: string[]
}

const useFilter = (initialFilters = {date_before: "", date_after: "", region_name: "", status: "",
    level: ["Маленькая яма", "Средняя яма", "Большая яма", "Разметка пешехода стирается", "Стрелка поворота стирается"],
    type: ["road_pits", "path_signs"]
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const [regions, setRegions] = useState([]);
    const [filters, setFilters] = useState<FilterType>(initialFilters);

    useEffect(() => {
        (async () => setRegions(await getRegions()))()
    }, [])

    const statuses = [{
        label: t("all_statuses"),
        value: "",
    }, {
        label: t("waiting"),
        value: "Ожидает"
    }, {
        label: t("fixed"),
        value: "Исправили"
    }, {
        label: t("in_work"),
        value: "В работе"
    }, {
        label: t("on_check"),
        value: "На проверке"
    }];

    const levelItems = [{
        label: t("small_pits"),
        value: "Маленькая яма",
        Icon: PinGreen
    }, {
        label: t("middle_pits"),
        value: "Средняя яма",
        Icon: PinOrange
    }, {
        label: t("big_pits"),
        value: "Большая яма",
        Icon: PinRed
    },{
        label: t("pedestrian_line_erasing"),
        value: "Разметка пешехода стирается",
        Icon: PedestrianLine
    }, {
        label: t("arrow_erasing"),
        value: "Стрелка поворота стирается",
        Icon: RoadSign
    }];

    const pitsSignsItems = [
        {
            label: t("road_pits"),
            value: "road_pits",
            Icon: PinGreen
        },
        {
            label: t("path_signs"),
            value: "path_signs",
            Icon: RoadSign
        }
    ]

    // Обработчик изменения значений select
    const handleSelectChange = useCallback((key: string, value: string) => {
        setFilters(prev => ({ ...prev, [key]: value }));
    }, []);

    // const handleChangeDate = useCallback((key: string, value: string) => {
    //     setFilters(prev => ({ ...prev, date: {...prev.date, [key]: value }}))
    // }, []);

    const handleChangeMobile = useCallback((newFilters: FilterType) => {
        setFilters(newFilters);
    }, []);

    // Обработчик для чекбоксов
    const handleCheckboxChange = useCallback((key: string, e: ChangeEvent<HTMLInputElement>) => {
        const { id } = e.target;
        setFilters(prev => ({
            ...prev,
            [key]: (() => {
                let newLevels = [...(prev as any)[key]];
                if (newLevels.includes(id)) {
                    newLevels = newLevels.filter(lvl => lvl !== id);
                } else {
                    newLevels.push(id);
                }
                return newLevels
            })(),
        }));
    }, []);

    // Функция сброса фильтров к начальному состоянию
    const resetFilters = useCallback(() => setFilters(initialFilters), [initialFilters]);

    const levels = useMemo(() => {
        const type = searchParams.get("type") || "road_pits";
        return levelItems.filter(level => {
            if (type === "road_pits") {
                return level.value !== "Разметка пешехода стирается" && level.value !== "Стрелка поворота стирается";
            }
            return level.value !== "Маленькая яма" && level.value !== "Средняя яма" && level.value !== "Большая яма";
        });
    }, [searchParams])

    return { filters, pitsSignsItems, handleSelectChange, handleChangeMobile, handleCheckboxChange, resetFilters, statuses, levels, regions };
};

export default useFilter;
