import React, {useEffect, useRef, useState} from "react";
import ApexCharts from "apexcharts";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import dayjs from "dayjs";
import {getRadialChartOptions} from "../configs";
import {BASE_URL} from "../../../services/constants";
import PeriodChangeMenu from "./PeriodChangeMenu";

type RadialChartType = {
    chart: {
        data?: number[]
    },
    cards: {
        new?: number,
        in_work?: number,
        repaired?: number
    }
}

const RadialChart = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<RadialChartType>({
        chart: {},
        cards: {}
    });
    const chartInstance = useRef<any>(null);
    const [period, setPeriod] = useState(dayjs().add(-30, "day").format("YYYY-MM-DD"));

    useEffect(() => {
        const getChartOptions = async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(`${BASE_URL}/detection/dashboard/donut_graph/`,
                    {params: {date_after: period, date_before: dayjs().format("YYYY-MM-DD")}}
                );
                setData(res.data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        getChartOptions();
    }, [period])

    useEffect(() => {
        if (data.chart.data && document.getElementById("radial-chart") && typeof ApexCharts !== "undefined") {
            if (chartInstance.current) {
                chartInstance.current.updateSeries(data.chart.data as Array<number>);
            } else {
                chartInstance.current = new ApexCharts(document.querySelector("#radial-chart"), getRadialChartOptions(data.chart.data,[t("repaired"), t("in_work"), t("waiting")]));
                chartInstance.current.render();
            }
        }
    }, [data.chart.data])

    if (!isLoading && Object.keys(data.cards).length === 0) {
        return null;
    }

    return (
        <div className="w-full md:w-[calc(100%/3)] h-fit bg-white rounded-lg shadow p-4 md:p-6">
            <div className="flex justify-between mb-3">
                <div className="flex items-center">
                    <div className="flex justify-center items-center">
                        <h5 className="text-xl font-bold leading-none text-gray-900 pe-1">{t("repairer_kpi")}</h5>
                    </div>
                </div>
            </div>

            <div className="bg-gray-50 p-3 rounded-lg">
                <div className="grid grid-cols-3 gap-3 mb-2">
                    <dl className="bg-orange-50 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-orange-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.new}</dt>
                        <dd className="text-orange-600 text-sm font-medium">{t("waitings")}</dd>
                    </dl>
                    <dl className="bg-teal-50 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-teal-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.in_work}</dt>
                        <dd className="text-teal-600 text-sm font-medium">{t("in_work")}</dd>
                    </dl>
                    <dl className="bg-blue-50 rounded-lg flex flex-col items-center justify-center h-[78px]">
                        <dt className="w-8 h-8 rounded-full bg-blue-100 text-sm font-medium flex items-center justify-center mb-1">{data.cards.repaired}</dt>
                        <dd className="text-blue-600 text-sm font-medium">{t("repaired")}</dd>
                    </dl>
                </div>
            </div>

            <div className="py-2" id="radial-chart"></div>

            <div className="grid grid-cols-1 items-center border-gray-200 border-t justify-between">
                <div className="flex justify-between items-center pt-5">
                    <PeriodChangeMenu
                        name="radial"
                        period={period}
                        setPeriod={setPeriod}
                    />
                    <Link
                        to="/tasks"
                        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600 
                        hover:text-blue-700 hover:bg-gray-100 px-3 py-2"
                    >
                        {t("task_board")}
                        <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </div>

    )
}

export default RadialChart;