export const getLineChartOptions = (data: Array<number>=[], categories: Array<string>=[], seriesName="New defects") => ({
    chart: {
        height: "200px",
        maxWidth: "100%",
        type: "area",
        fontFamily: "Inter, sans-serif",
        dropShadow: {
            enabled: false,
        },
        toolbar: {
            show: false,
        }
    },
    tooltip: {
        enabled: true,
        x: {
            show: false,
        },
    },
    fill: {
        type: "gradient",
        gradient: {
            opacityFrom: 0.55,
            opacityTo: 0,
            shade: "#1C64F2",
            gradientToColors: ["#1C64F2"],
        },
    },
    dataLabels: {
        enabled: false,
    },
    stroke: {
        width: 6,
    },
    grid: {
        show: false,
        strokeDashArray: 4,
        padding: {
            left: 2,
            right: 2,
            top: 0
        },
    },
    series: [
        {
            name: seriesName,
            data,
            color: "#1A56DB",
        },
    ],
    xaxis: {
        categories,
        labels: {
            show: false,
        },
        axisBorder: {
            show: false,
        },
        axisTicks: {
            show: false,
        },
    },
    yaxis: {
        show: false,
    }
})

export const getRadialChartOptions = (data: Array<number>=[59,69,79], labels: Array<string>=["1", "2", "3"]) => {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return {
        series: data,
        colors: ["#1C64F2", "#16BDCA", "#FDBA8C"],
        chart: {
            height: "280px",
            width: "100%",
            type: "radialBar",
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: {
            radialBar: {
                track: {
                    background: "#E5E7EB",
                },
                dataLabels: {
                    show: false,
                },
                hollow: {
                    margin: 0,
                    size: "32%",
                }
            },
        },
        grid: {
            show: false,
            strokeDashArray: 4,
            padding: {
                left: 2,
                right: 2,
                top: -23,
                bottom: -20,
            },
        },
        labels,
        legend: {
            show: !isSafari,
            position: "bottom",
            fontFamily: "Inter, sans-serif",
        },
        tooltip: {
            enabled: false,
            x: {
                show: false,
            },
        },
        yaxis: {
            show: false,
            labels: {
                formatter(value: any) {
                    return `${value}%`;
                }
            }
        }
    }
}